import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Toast, ToastBody } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Modal from 'react-modal';

export class Screening extends Component {
    static displayName = Screening.name;

    constructor(props) {
        super(props);

        this.state = {
            screeningId: userFunctions.GuidEmpty(),
            studentId: userFunctions.GuidEmpty(),
            studentInfo: [],
            distantVisualAcuities: [],
            nearVisualAcuities: [],
            visionScreeningResults: [],
            exams: [],
            examInfo: [{ examDate: "No Data", glasses: "" }],
            rightDistanceVisualAcuitiesId: userFunctions.GuidEmpty(),
            leftDistanceVisualAcuitiesId: userFunctions.GuidEmpty(),
            rightNearVisualAcuitiesId: userFunctions.GuidEmpty(),
            leftNearVisualAcuitiesId: userFunctions.GuidEmpty(),
            visionScreeningResultId: userFunctions.GuidEmpty(),
            wearsGlasses: false,
            examConsentOnFile: false,
            screeningNotes: '',
            loading: false,
            screeningDate: '',
            showToast: false,
            toastMessage: '',
            a1: false,
            a2: false,
            isDoctor: false,
            showDeleteScreeningModal: false
        }
    }

    async componentDidMount() {
        const isInRole = await userFunctions.IsInRole("Doctor");
        const username = await userFunctions.Username();
        this.setState({ isDoctor: isInRole, username: username });
        // load student
        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('Id');
        this.screeningId = this.query.get('sid');
        if (this.queryId) {
            this.setState({ studentId: this.queryId, screeningId: this.screeningId == null ? userFunctions.GuidEmpty() : this.screeningId });
            //this.getExamInfo(this.queryId);
            this.getVisionScreeningResults();
            this.getDistanceVisualAcuities();
            this.getNearVisualAcuities();
            this.GetStudentScreeningInfo(this.queryId, this.screeningId);
            this.GetExamGrid(this.queryId);
        }
    }

    render() {
        return (
            <div>
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Container style={{ marginTop: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Screening</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '5px' }}>
                                <table className="studentScreeningTable">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Name:
                                            </td>
                                            <td>
                                                {this.state.studentInfo.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Date Of Birth:
                                            </td>
                                            <td>
                                                {this.state.studentInfo.dateOfBirth}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Site:
                                            </td>
                                            <td>
                                                {this.state.studentInfo.site}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Grade:
                                            </td>
                                            <td>
                                                {this.state.studentInfo.grade}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Teacher:
                                            </td>
                                            <td>
                                                {this.state.studentInfo.teacher}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Screening Date:
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => this.setState({ screeningDate: e.target.value, a1: false })}
                                                    style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    value={this.state.screeningDate}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '15px' }}>
                                <label>{this.state.studentInfo.screeningDate}</label>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '15px' }}>
                                <h5>Distance Visual Acuities</h5>
                                <div className={'row-label-input-noleftmargin'}>
                                    <label className="select-label">R:</label>
                                    <select className="form-control-select"
                                        onChange={(e) => this.setState({ rightDistanceVisualAcuitiesId: e.target.value })}
                                        value={this.state.rightDistanceVisualAcuitiesId}
                                    >
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.distantVisualAcuities.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className={'row-label-input-noleftmargin'}>
                                    <label className="select-label">L:</label>
                                    <select className="form-control-select"
                                        onChange={(e) => this.setState({ leftDistanceVisualAcuitiesId: e.target.value })}
                                        value={this.state.leftDistanceVisualAcuitiesId}
                                    >
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.distantVisualAcuities.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '15px' }}>
                                <h5>Near Visual Acuities</h5>
                                <div className={'row-label-input-noleftmargin'}>
                                    <label className="select-label">R:</label>
                                    <select className="form-control-select"
                                        onChange={(e) => this.setState({ rightNearVisualAcuitiesId: e.target.value, a2: false })}
                                        value={this.state.rightNearVisualAcuitiesId}
                                    >
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.nearVisualAcuities.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className={'row-label-input-noleftmargin'}>
                                    <label className="select-label">L:</label>
                                    <select className="form-control-select"
                                        onChange={(e) => this.setState({ leftNearVisualAcuitiesId: e.target.value, a2: false })}
                                        value={this.state.leftNearVisualAcuitiesId}
                                    >
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.nearVisualAcuities.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </Col>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <div className={'row-label-input max-height-15vh-overflow'}>
                                <table className="gridTable">
                                    <thead>
                                        <tr>
                                            <th>Exam Date</th>
                                            <th>Glasses Needed?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.exams.map((item, i) =>
                                            this.state.isDoctor ?
                                                <tr onClick={() => {
                                                    window.location.href = "/ExamPreTest?studentId=" + this.state.studentId + "&examId=" + item.id;
                                                }}>
                                                    <td>
                                                        {item.examDate}
                                                    </td>
                                                    <td>
                                                        {item.glassesNeeded}
                                                    </td>
                                                </tr>
                                                :
                                                <tr style={{ cursor: 'default' }}>
                                                    <td>
                                                        {item.examDate}
                                                    </td>
                                                    <td>
                                                        {item.glassesNeeded}
                                                    </td>
                                                </tr>

                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className={'row-label-input'} style={{ marginTop: '10px' }}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ wearsGlasses: !this.state.wearsGlasses }) }}
                                    checked={this.state.wearsGlasses}
                                    style={{ marginTop: '10px' }}
                                    className="form-control-checkbox-disabled"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '8px' }}>Wears Glasses per Nurse</label>
                            </div>
                            <div className={'row-label-input'} style={{ marginBottom: '20px' }}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ examConsentOnFile: !this.state.examConsentOnFile }) }}
                                    checked={this.state.examConsentOnFile}
                                    style={{ marginTop: '5px' }}
                                    className="form-control-checkbox-disabled"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '3px' }}>Current Year Exam Consent On File</label>
                            </div>
                            <div className={'row-label-input'}>
                                <label style={{ width: '33%', display: 'flex', alignItems: 'flex-start', paddingTop: '5px', justifyContent: 'flex-end', padding: '8px' }}>Vision Screening Results</label>
                                <select className="form-control"
                                    onChange={(e) => this.setState({ visionScreeningResultId: e.target.value, a2: false })}
                                    value={this.state.visionScreeningResultId}
                                    style={this.state.a2 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.visionScreeningResults.map((item) =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className={'row-label-input'}>
                                <label style={{ width: '33%', display: 'flex', alignItems: 'flex-start', paddingTop: '5px', justifyContent: 'flex-end', padding: '8px' }}>Screening Notes</label>
                                <textarea
                                    onChange={(e) => {
                                        this.setState({ screeningNotes: e.target.value });
                                    }}
                                    placeholder=''
                                    value={this.state.screeningNotes == null ? '' : this.state.screeningNotes}
                                    style={{ width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
                        {this.state.username == 'katemckearn@eyethrive.org' || this.state.username == 'brian@swipsystems.com' || this.state.username == 'ashleywildermuth@eyethrive.org' ?
                            <Button
                                className='delete'
                                style={{ marginRight: '10px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={async () => await this.DeleteScreening()}
                                disabled={this.state.loading ? true : false}
                            >
                                Delete Screening
                            </Button> : null}
                        <Button
                            color='primary'
                            onClick={() => this.cancel()}
                            style={{ marginRight: '10px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                            disabled={this.state.loading ? true : false}
                        >
                            Cancel
                        </Button>
                        <Button
                            color='primary'
                            onClick={async () => await this.saveScreening()}
                            style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                            disabled={this.state.loading ? true : false}
                        >
                            Save
                        </Button>
                    </div>
                </Container>
                <div>
                    <Modal
                        isOpen={this.state.showDeleteScreeningModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showDeleteScreeningModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Screening Confirmation</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ width: '100%', display: 'flex', textAlign: 'center', fontSize: '16px' }}>
                                                This action will delete the student screening.<br /><br />
                                                Are you sure you want to delete this screening?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ showDeleteScreeningModal: false })
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className='delete'
                                                    style={{
                                                        marginLeft: '40px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.handleDeleteScreening();
                                                        this.setState({ showDeleteScreeningModal: false })
                                                    }}
                                                >
                                                    Delete Screening
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
            </div >
        );
    }

    async DeleteScreening() {
        this.setState({ showDeleteScreeningModal: true })
    }

    async handleDeleteScreening() {
        const token = await authService.getAccessToken();
        var id = this.state.screeningId;
        var studentId = this.state.studentId;

        var dto = {
            Id: id
        }

        await fetch('api/Student/DeleteScreening', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                window.location.href = "/StudentDetails?Id=" + studentId;
            })
            .catch((error) => {
                console.log('error in deleteScreening - ' + error);
            });
    }

    async GetExamGrid(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/exam/getExamGrid?studentId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    exams: data,
                    loading: false
                });
            })
            .catch(error => {
                console.log("error in GetExamGrid - " + error);
                this.setState({ loading: false });
            });;
    }

    cancel() {
        window.location.href = "/StudentSearch";
    }

    async saveScreening() {
        if (this.state.screeningDate.length == 0) {
            this.setState({ a1: true });
        }
        else if (this.state.visionScreeningResultId == 0) {
            this.setState({ a2: true });
        }
        else {
            const token = await authService.getAccessToken();
            var dto = {
                Id: this.state.screeningId == userFunctions.GuidEmpty() ? null : this.state.screeningId,
                StudentId: this.state.studentId == userFunctions.GuidEmpty() ? null : this.state.studentId,
                ScreeningDate: this.state.screeningDate,
                RightDistanceVisualAcuitiesID: this.state.rightDistanceVisualAcuitiesId == userFunctions.GuidEmpty() ? null : this.state.rightDistanceVisualAcuitiesId,
                LeftDistanceVisualAcuitiesID: this.state.leftDistanceVisualAcuitiesId == userFunctions.GuidEmpty() ? null : this.state.leftDistanceVisualAcuitiesId,
                RightNearVisualAcuitiesID: this.state.rightNearVisualAcuitiesId == userFunctions.GuidEmpty() ? null : this.state.rightNearVisualAcuitiesId,
                LeftNearVisualAcuitiesID: this.state.leftNearVisualAcuitiesId == userFunctions.GuidEmpty() ? null : this.state.leftNearVisualAcuitiesId,
                VisionScreeningResultsID: this.state.visionScreeningResultId == userFunctions.GuidEmpty() ? null : this.state.visionScreeningResultId,
                Notes: this.state.screeningNotes
            };

            await fetch('api/Student/SaveScreening', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    window.location.href = "/StudentSearch";
                })
                .catch((error) => {
                    console.log('error in saveScreening - ' + error);
                });
        }
    }

    async GetStudentScreeningInfo() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Student/StudentScreening?id=' + this.state.studentId + "&sid=" + this.state.screeningId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    studentInfo: data,
                    wearsGlasses: data.wearsGlassesPerNurse,
                    examConsentOnFile: data.examConsentOnFile,
                    screeningDate: data.screeningData.screeningDate,
                    screeningNotes: data.screeningData.notes
                });

                if (data.screeningData) {
                    this.setState({
                        leftDistanceVisualAcuitiesId: data.screeningData.leftDistanceVisualAcuitiesID,
                        rightDistanceVisualAcuitiesId: data.screeningData.rightDistanceVisualAcuitiesID,
                        leftNearVisualAcuitiesId: data.screeningData.leftNearVisualAcuitiesID,
                        rightNearVisualAcuitiesId: data.screeningData.rightNearVisualAcuitiesID,
                        visionScreeningResultId: data.screeningData.visionScreeningResultsID,
                    });
                }

                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in GetStudentScreeningInfo - ' + error.message);
            });
    }

    async getVisionScreeningResults() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Student/GetVisionScreeningResults', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    visionScreeningResults: data
                });
                this.setState({ loading: false });

            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getVisionScreeningResults - ' + error.message);
            });
    }

    async getDistanceVisualAcuities() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Student/GetDistanceVisualAcuities', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    distantVisualAcuities: data
                });
                this.setState({ loading: false });

            })
            .catch((error) => {
                console.log('error in getDistanceVisualAcuities - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async getNearVisualAcuities() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Student/GetNearVisualAcuities', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    nearVisualAcuities: data
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getNearVisualAcuities - ' + error.message);
                this.setState({ loading: false });
            });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '' });
            this.setState({ showToast: false });
        }, 3500);
    }
}