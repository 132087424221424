import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner } from 'reactstrap';
import './layout/site.css';
import { BsPlusCircleFill } from 'react-icons/bs';
import userFunctions from './UserFunctions';
import FileHelper from './FileHelper';
import Autocomplete from "./Autocomplete";
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';

export class Reporting extends Component {
    static displayName = Reporting.name;

    constructor(props) {
        super(props);

        this.state = {
            siteList: [],
            sites: [],
            site: '',
            mvcSiteLocationId: userFunctions.GuidEmpty(),
            districtList: [],
            gradeList: [],
            dateOfBirth: '',
            siteId: userFunctions.GuidEmpty(),
            districtId: userFunctions.GuidEmpty(),
            gradeId: userFunctions.GuidEmpty(),
            client: '',
            search: '',
            students: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            isDoctor: false,
            reportingType: 'vs',
            searchResults: [],
            from: undefined,
            to: undefined,
            vsChecked: true,
            mvcChecked: false,
            dispChecked: false,
            showDeleteStudentModal: false
        };
        //this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    //handleClick(event) {
    //    let id = Number(event.target.id);

    //    if (id === 0) {
    //        this.setState({
    //            currentPage: 1
    //        });
    //    } else if (id === -1) {
    //        this.setState({
    //            currentPage: Math.ceil(this.state.students.length / this.state.itemsPerPage)
    //        });
    //    } else {
    //        this.setState({
    //            currentPage: Number(event.target.id)
    //        });
    //    }
    //}

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    //handlePageSizeChange(event) {
    //    let pageSize = Number(event.target.value);
    //    this.setState({ itemsPerPage: pageSize });
    //    if (this.state.currentPage > Math.ceil(this.state.students.length / pageSize)) {
    //        this.setState({ currentPage: Math.ceil(this.state.students.length / pageSize) })
    //    }
    //}

    async componentDidMount() {
        this.getDistricts();
        this.getAutocompleteSites();
        const username = await userFunctions.Username();
        this.setState({ username: username });
        Modal.setAppElement('body');
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchStudents();
        }
    }

    newStudent() {
        window.location.href = '/StudentDetails';
    }

    handleDeleteStudent(id) {
        this.setState({ showDeleteStudentModal: true, studentId: id });
    }

    async deleteStudent() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var dto = {
            Id: this.state.studentId
        };

        await fetch('api/Student/DeleteStudent', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response;
                }
            })
            .then((data) => {
                if (data) {
                    //this.search();
                    var _searchResults = this.state.searchResults;
                    var index = _searchResults.findIndex(f => f.studentId == this.state.studentId);
                    if (index != -1) {
                        _searchResults.splice(index, 1);
                        this.state({ searchResults: _searchResults });
                    }
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in deleteStudent - ' + error);
            });
    }


    render() {
        // Logic for displaying sites!
        //const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        //const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        //const currentItems = this.state.students.slice(indexOfFirstItem, indexOfLastItem);
        //const currentItems = this.state.students;

        const renderItems = this.state.searchResults?.map((item, i) => {
            return (
                this.state.reportingType == 'vs' ?
                    <tr key={i}>
                        <td>
                            {item.firstName}
                        </td>
                        <td>
                            {item.lastName}
                        </td>
                        <td>
                            {item.dateOfBirth}
                        </td>
                        <td>
                            {item.grade}
                        </td>
                        <td>
                            {item.teacher}
                        </td>
                        <td>
                            {item.photoConsent}
                        </td>
                        <td>
                            {item.screeningDate}
                        </td>
                        <td>
                            {item.rightDistanceAcuities}
                        </td>
                        <td>
                            {item.leftDistanceAcuities}
                        </td>
                        <td>
                            {item.rightNearAcuities}
                        </td>
                        <td>
                            {item.leftNearAcuities}
                        </td>
                        <td>
                            {item.screeningResult}
                        </td>
                        <td>
                            {item.wearsGlasses}
                        </td>
                        <td>
                            {item.consentOnFile}
                        </td>
                        <td>
                            {item.ieP504Plan}
                        </td>
                        <td>
                            {item.site}
                        </td>
                        <td>
                            {item.schoolOther}
                        </td>
                        <td>
                            {item.district}
                        </td>
                        <td>
                            {item.districtOther}
                        </td>
                        <td>
                            {item.mvcVisitLocation}
                        </td>
                    </tr>
                    : this.state.reportingType == 'mvc' ?
                        <tr key={i}>
                            {this.state.username == 'katemckearn@eyethrive.org' || this.state.username == 'brian@swipsystems.com' || this.state.username == 'adamblumoff@eyethrive.org' || this.state.username == 'ashleywildermuth@eyethrive.org' ?
                                <td className="deleteUploadCell">
                                    <IoTrashOutline color="#000" size={21} style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteStudent(item.studentId)} />
                                </td> : null}
                            <td>
                                {item.firstName}
                            </td>
                            <td>
                                {item.lastName}
                            </td>
                            <td>
                                {item.dateOfBirth}
                            </td>
                            <td>
                                {item.grade}
                            </td>
                            <td>
                                {item.teacher}
                            </td>
                            <td>
                                {item.photoConsent}
                            </td>
                            <td>
                                {item.site}
                            </td>
                            <td>
                                {item.schoolOther}
                            </td>
                            <td>
                                {item.district}
                            </td>
                            <td>
                                {item.districtOther}
                            </td>
                            <td>
                                {item.examDate}
                            </td>
                            <td>
                                {item.mvcSiteLocation}
                            </td>
                            <td>
                                {item.glassesNeeded}
                            </td>
                            <td>
                                {item.numberGlassesReceived}
                            </td>
                            <td>
                                {item.referral}
                            </td>
                            <td>
                                {item.numberGlassesOnSite}
                            </td>
                            <td>
                                {item.numberGlassesOffSite}
                            </td>
                            <td>
                                {item.gender}
                            </td>
                            <td>
                                {item.ethnicity}
                            </td>
                            <td>
                                {item.race}
                            </td>
                            <td>
                                {item.homeZipCode}
                            </td>
                            <td>
                                {item.medicaidPatient}
                            </td>
                            <td>
                                {item.medicaidIDNumber}
                            </td>
                            <td>
                                {item.freeReducedLunch}
                            </td>
                            <td>
                                {item.ieP504Plan}
                            </td>
                            <td>
                                {item.doctor}
                            </td>
                        </tr>
                        : this.state.reportingType == 'disp' ?
                            <tr>
                                <td>{item.dateDispensed}</td>
                                <td>{item.dispenseType}</td>
                                <td>{item.binNumber}</td>
                                <td>{item.currentInventory}</td>
                                <td>{item.manufacturer}</td>
                                <td>{item.model}</td>
                                <td>{item.material}</td>
                                <td>{item.description}</td>
                                <td>{item.color}</td>
                                <td>{item.eyeSize}</td>
                                <td>{item.sizeRange}</td>
                                <td>{item.bridge}</td>
                                <td>{item.temple}</td>
                                <td>{item.jobLocation}</td>
                                <td>{item.site}</td>
                                <td>{item.schoolOther}</td>
                                <td>{item.district}</td>
                                <td>{item.districtOther}</td>
                                <td>{item.mvcVisitLocation}</td>
                            </tr>
                            : this.state.reportingType === 'repl' ?
                                <tr>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.dateOfBirth}</td>
                                    <td>{item.site}</td>
                                    <td>{item.district}</td>
                                    <td>{item.mvcSiteLocation}</td>
                                    <td>{item.submittedBy}</td>
                                    <td>{item.valid}</td>
                                    <td>{item.replacementReason}</td>
                                    <td>{item.dateOrderReceived}</td>
                                    <td>{item.dateMailed}</td>
                                </tr>
                                : null
            )
        });

        // Logic for displaying page numbers
        //const pageNumbers = [];
        //for (let i = 1; i <= Math.ceil(this.state.students.length / this.state.itemsPerPage); i++) {
        //    pageNumbers.push(i);
        //}

        //const renderPageNumbers = pageNumbers.map(number => {
        //    return (
        //        <option value={number}>{number}</option>
        //    );
        //});

        return (
            <div>
                {
                    this.state.loading === true ?
                        <div>
                            <Spinner color="black" children='' style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '999' }} />
                        </div>
                        : null
                }
                < Container style={{ marginTop: '50px', marginBottom: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Reporting</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="radio-group">
                                <div>
                                    <input
                                        id="vs"
                                        type="radio"
                                        name="reportType"
                                        defaultChecked={true}
                                        onChange={() => this.setState({
                                            reportingType: 'vs',
                                            vsChecked: !this.state.vsChecked,
                                            searchResults: []
                                        })} />
                                    <label htmlFor="vs">Vision Screening</label>
                                </div>
                                <div>
                                    <input
                                        id="mvc"
                                        type="radio"
                                        name="reportType"
                                        //checked={this.state.mvcChecked}
                                        onChange={() => this.setState({
                                            reportingType: 'mvc',
                                            mvcChecked: !this.state.mvcChecked,
                                            searchResults: []
                                        })} />
                                    <label htmlFor="mvc">Mobile Vision Clinic</label>
                                </div>
                                <div>
                                    <input
                                        id="disp"
                                        type="radio"
                                        name="reportType"
                                        //checked={this.state.dispChecked}
                                        onChange={() => {
                                            this.setState({
                                                reportingType: 'disp',
                                                dispChecked: !this.state.dispChecked,
                                                searchResults: []
                                            });
                                        }} />
                                    <label htmlFor="repl">Dispensed Frames</label>
                                </div>
                                <div>
                                    <input id="ref" type="radio" name="reportType" onChange={() => this.setState({ reportingType: 'ref' })} disabled={true} />
                                    <label htmlFor="ref">Referrals</label>
                                </div>
                                <div>
                                    <input id="repl"
                                        type="radio"
                                        name="reportType"
                                        onChange={() => this.setState({
                                            reportingType: 'repl',
                                            replChecked: !this.state.replChecked,
                                            searchResults: []
                                        })}
                                    />
                                    <label htmlFor="repl">Replacements</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <table cellPadding={5} className="layoutTable">
                            <tbody>
                                {this.state.reportingType !== 'repl' ?
                                    <tr>
                                        <td align="right" valign="middle">
                                            Date From
                                        </td>
                                        <td align="left" valign="middle">
                                            <Input
                                                type='date'
                                                onChange={(e) => this.setState({ from: e.target.value })}
                                                value={this.state.from}
                                                style={{ width: '150px' }}
                                            />
                                        </td>
                                        <td align="right" valign="middle">
                                            Date To
                                        </td>
                                        <td align="left" valign="middle">
                                            <Input
                                                type='date'
                                                onChange={(e) => this.setState({ to: e.target.value })}
                                                value={this.state.to}
                                                style={{ width: '150px' }}
                                            />
                                        </td>
                                    </tr> : null}
                                {this.state.reportingType === 'repl' ?
                                    <tr>
                                        <td align="left" valign="middle">
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '5px' }}>
                                                Date Form Received From
                                                <Input
                                                    type='date'
                                                    onChange={(e) => this.setState({ dateFormReceivedFrom: e.target.value })}
                                                    value={this.state.dateFormReceivedFrom}
                                                    style={{ width: '150px', marginLeft: '5px', marginRight: '10px' }}
                                                />
                                                Date Form Received To
                                                <Input
                                                    type='date'
                                                    onChange={(e) => this.setState({ dateFormReceivedTo: e.target.value })}
                                                    value={this.state.dateFormReceivedTo}
                                                    style={{ width: '150px', marginLeft: '5px', marginRight: '30px' }}
                                                />
                                                Date Mailed From
                                                <Input
                                                    type='date'
                                                    onChange={(e) => this.setState({ dateMailedFrom: e.target.value })}
                                                    value={this.state.dateMailedFrom}
                                                    style={{ width: '150px', marginLeft: '5px', marginRight: '10px' }}
                                                />
                                                Date Mailed To
                                                <Input
                                                    type='date'
                                                    onChange={(e) => this.setState({ dateMailedTo: e.target.value })}
                                                    value={this.state.dateMailedTo}
                                                    style={{ width: '150px', marginLeft: '5px' }}
                                                />
                                            </div>
                                        </td>
                                    </tr> : null}
                                {this.state.reportingType === 'repl' ?
                                    <tr>
                                        <td align="left" valign="middle">
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                District
                                                <select className="form-control" value={this.state.districtId} onChange={(e) => {
                                                    this.setState({ districtId: e.target.value == 0 ? undefined : e.target.value, siteId: undefined });
                                                    if (e.target.value == 0) {
                                                        this.setState({ siteList: [] });
                                                    }
                                                    else {
                                                        this.getSitesByDistrictId(e.target.value);
                                                    }
                                                }}
                                                    style={{ marginLeft: '5px', marginRight: '10px', minWidth: '150px', width: 'fit-content' }}>
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.districtList.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                                School
                                                <select className="form-control" value={this.state.siteId} onChange={(e) => {
                                                    this.setState({ siteId: e.target.value == 0 ? undefined : e.target.value });
                                                }}
                                                    style={{ marginLeft: '5px', marginRight: '10px', minWidth: '150px', width: 'fit-content' }}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.siteList?.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                                <Button
                                                    color='primary'
                                                    onClick={async () => await this.search()}
                                                    style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content', marginLeft: '5px', marginRight: '10px', }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Search
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.exportToCSV()}
                                                    style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content', marginLeft: '5px' }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Export To CSV
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    : null}
                                <tr>
                                    {this.state.reportingType !== 'repl' ?
                                        <>
                                            <td align="right" valign="middle">
                                                District
                                            </td>
                                            <td align="left" valign="middle">
                                                <select className="form-control" value={this.state.districtId} onChange={(e) => {
                                                    this.setState({ districtId: e.target.value == 0 ? undefined : e.target.value, siteId: undefined });
                                                    if (e.target.value == 0) {
                                                        this.setState({ siteList: [] });
                                                    }
                                                    else {
                                                        this.getSitesByDistrictId(e.target.value);
                                                    }
                                                }}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.districtList.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td align="right" valign="middle">
                                                School
                                            </td>
                                            <td align="left" valign="middle">
                                                <select className="form-control" value={this.state.siteId} onChange={(e) => {
                                                    this.setState({ siteId: e.target.value == 0 ? undefined : e.target.value });
                                                }}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.siteList?.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </> : null}
                                    {this.state.reportingType === 'mvc' || this.state.reportingType === 'disp' ?
                                        <>
                                            <td>
                                                MVC Visit Location
                                            </td>
                                            <td>
                                                <Autocomplete
                                                    suggestions={this.state.sites}
                                                    placeholder="MVC Visit Location"
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => {
                                                        this.setState({ mvcSiteLocationId: e.Id })
                                                    }}
                                                    value={this.state.site}
                                                />
                                            </td>
                                        </> : null}
                                    {this.state.reportingType !== 'repl' ?
                                        <>
                                            <td align="left" valign="middle">
                                                <Button
                                                    color='primary'
                                                    onClick={async () => await this.search()}
                                                    style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Search
                                                </Button>
                                            </td>
                                            <td align="right" valign="middle">
                                                <Button
                                                    color='primary'
                                                    onClick={() => this.exportToCSV()}
                                                    style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Export To CSV
                                                </Button>
                                            </td>
                                        </> : null}
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <div style={{ overflow: 'auto', maxHeight: '40vh', height: '100%' }}>
                                <Table className="gridTable">
                                    <thead>
                                        {this.state.reportingType === 'vs' ?
                                            <tr>
                                                <th>
                                                    First Name
                                                </th>
                                                <th>
                                                    Last Name
                                                </th>
                                                <th>
                                                    Date Of Birth
                                                </th>
                                                <th>
                                                    Grade
                                                </th>
                                                <th>
                                                    Teacher
                                                </th>
                                                <th>
                                                    Photo Consent
                                                </th>
                                                <th>
                                                    Screening Date
                                                </th>
                                                <th>
                                                    Distance Visual Acuities Right
                                                </th>
                                                <th>
                                                    Distance Visual Acuities Left
                                                </th>
                                                <th>
                                                    Near Visual Acuities Right
                                                </th>
                                                <th>
                                                    Near Visual Acuities Left
                                                </th>
                                                <th>
                                                    Screening Result
                                                </th>
                                                <th>
                                                    Wears Glasses?
                                                </th>
                                                <th>
                                                    Current Year Exam Consent On File
                                                </th>
                                                <th>
                                                    IEP/504 Plan
                                                </th>
                                                <th>
                                                    School
                                                </th>
                                                <th>
                                                    School Other
                                                </th>
                                                <th>
                                                    District
                                                </th>
                                                <th>
                                                    District Other
                                                </th>
                                                <th>
                                                    MVC Visit Location
                                                </th>
                                            </tr>
                                            : null}
                                        {this.state.reportingType === 'mvc' ?
                                            <tr>
                                                {this.state.username === 'katemckearn@eyethrive.org' || this.state.username === 'brian@swipsystems.com' || this.state.username === 'adamblumoff@eyethrive.org' ?
                                                    <th>
                                                    </th> : null}
                                                <th>
                                                    First Name
                                                </th>
                                                <th>
                                                    Last Name
                                                </th>
                                                <th>
                                                    Date Of Birth
                                                </th>
                                                <th>
                                                    Grade
                                                </th>
                                                <th>
                                                    Teacher
                                                </th>
                                                <th>
                                                    Photo Consent
                                                </th>
                                                <th>
                                                    School
                                                </th>
                                                <th>
                                                    School Other
                                                </th>
                                                <th>
                                                    District
                                                </th>
                                                <th>
                                                    District Other
                                                </th>
                                                <th>
                                                    Exam Date
                                                </th>
                                                <th>
                                                    MVC Visit Location
                                                </th>
                                                <th>
                                                    Glasses Needed
                                                </th>
                                                <th>
                                                    # Glasses Received
                                                </th>
                                                <th>
                                                    Referral
                                                </th>
                                                <th>
                                                    # Glasses On Site
                                                </th>
                                                <th>
                                                    # Glasses Off Site
                                                </th>
                                                <th>
                                                    Gender
                                                </th>
                                                <th>
                                                    Ethnicity
                                                </th>
                                                <th>
                                                    Race
                                                </th>
                                                <th>
                                                    Home Zip Code
                                                </th>
                                                <th>
                                                    Medicaid Patient
                                                </th>
                                                <th>
                                                    Medicaid ID#
                                                </th>
                                                <th>
                                                    Free / Reduced Lunch
                                                </th>
                                                <th>
                                                    IEP / 504 Plan
                                                </th>
                                                <th>
                                                    Exam Doctor
                                                </th>
                                            </tr>
                                            : null}
                                        {this.state.reportingType === 'disp' ?
                                            <tr>
                                                <th>
                                                    Date Dispensed
                                                </th>
                                                <th>
                                                    Dispense Type
                                                </th>
                                                <th>
                                                    Bin #
                                                </th>
                                                <th>
                                                    Current Inventory
                                                </th>
                                                <th>
                                                    Manufacturer
                                                </th>
                                                <th>
                                                    Model
                                                </th>
                                                <th>
                                                    Material
                                                </th>
                                                <th>
                                                    Description
                                                </th>
                                                <th>
                                                    Color
                                                </th>
                                                <th>
                                                    Eye Size
                                                </th>
                                                <th>
                                                    Size Range
                                                </th>
                                                <th>
                                                    Bridge
                                                </th>
                                                <th>
                                                    Temple
                                                </th>
                                                <th>
                                                    Job Location
                                                </th>
                                                <th>
                                                    School
                                                </th>
                                                <th>
                                                    School Other
                                                </th>
                                                <th>
                                                    District
                                                </th>
                                                <th>
                                                    District Other
                                                </th>
                                                <th>
                                                    MVC Visit Location
                                                </th>
                                            </tr> : null}
                                        {this.state.reportingType === 'repl' ?
                                            <tr>
                                                <th>
                                                    First Name
                                                </th>
                                                <th>
                                                    Last Name
                                                </th>
                                                <th>
                                                    Date of Birth
                                                </th>
                                                <th>
                                                    School
                                                </th>
                                                <th>
                                                    District
                                                </th>
                                                <th>
                                                    MVC Location
                                                </th>
                                                <th>
                                                    Submitted By
                                                </th>
                                                <th>
                                                    Valid/Invalid
                                                </th>
                                                <th>
                                                    Replacement Reason
                                                </th>
                                                <th>
                                                    Date Order Received
                                                </th>
                                                <th>
                                                    Date Mailed
                                                </th>
                                            </tr> : null}
                                    </thead>
                                    <tbody>
                                        {this.state.searchResults?.length > 0 ?
                                            <>
                                                {renderItems}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={99} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                    No Results Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container >
                <div>
                    <Modal
                        isOpen={this.state.showDeleteStudentModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showDeleteStudentModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Student Confirmation</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ width: '100%', display: 'flex', textAlign: 'center', fontSize: '16px' }}>
                                                This action will delete the student and all data associated to this student.<br /><br />
                                                Are you sure you want to delete the student?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ showDeleteStudentModal: false })
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className='delete'
                                                    style={{
                                                        marginLeft: '40px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.deleteStudent();
                                                        this.setState({ showDeleteStudentModal: false })
                                                    }}
                                                >
                                                    Delete Student
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>

            </div >
        )
    }

    async search() {
        const token = await authService.getAccessToken();

        if (this.state.reportingType == 'vs') {
            this.setState({ loading: true, searchResults: [] });
            var dto = {
                From: this.state.from ?? null,
                To: this.state.to ?? null,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == "" ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == "" ? null : this.state.siteId
            }

            await fetch('api/Student/VisionScreeningReportSearch', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({
                        searchResults: data,
                        loading: false,
                        noResults: false
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Reporting.search - ' + error);
                });
        }
        else if (this.state.reportingType == 'mvc') {
            this.setState({ loading: true, searchResults: [] });
            var dto = {
                From: this.state.from ?? null,
                To: this.state.to ?? null,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == "" ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == "" ? null : this.state.siteId,
                MVCVisitLocationId: this.state.mvcSiteLocationId == userFunctions.GuidEmpty() || this.state.mvcSiteLocationId == 0 || this.state.mvcSiteLocationId == "" ? null : this.state.mvcSiteLocationId
            }

            await fetch('api/Exam/ExamReportingSearch', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({
                        searchResults: data,
                        loading: false,
                        noResults: false
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Reporting.search - ' + error);
                });
        }
        else if (this.state.reportingType == 'disp') {
            this.setState({ loading: true, searchResults: [] });
            var dto = {
                From: this.state.from ?? null,
                To: this.state.to ?? null,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == "" ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == "" ? null : this.state.siteId,
                MVCVisitLocationId: this.state.mvcSiteLocationId == userFunctions.GuidEmpty() || this.state.mvcSiteLocationId == 0 || this.state.mvcSiteLocationId == "" ? null : this.state.mvcSiteLocationId
            }

            await fetch('api/Lab/DispensedFrameReportSearch', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data) {
                        this.setState({
                            searchResults: data,
                            loading: false,
                            noResults: false
                        });
                    }
                    else {
                        this.setState({
                            searchResults: [],
                            loading: false,
                            noResults: true
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Reporting.search(dispensedframe) - ' + error);
                });
        }
        else if (this.state.reportingType === 'repl') {
            this.setState({ loading: true, searchResults: [] });
            var dto = {
                DateFormReceivedFrom: this.state.dateFormReceivedFrom ?? null,
                DateFormReceivedTo: this.state.dateFormReceivedTo ?? null,
                DateMailedFrom: this.state.dateMailedFrom ?? null,
                DateMailedTo: this.state.dateMailedTo ?? null,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == "" ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == "" ? null : this.state.siteId,
            }

            await fetch('api/student/ReplacementFrameReportSearch', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data) {
                        this.setState({
                            searchResults: data,
                            loading: false,
                            noResults: false
                        });
                    }
                    else {
                        this.setState({
                            searchResults: [],
                            loading: false,
                            noResults: true
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('Reporting.search(replacementframe) - ' + error);
                });
        }
    }

    async getAutocompleteSites() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSites', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ sites: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSites - ' + error.message, 'color:#ff0000')
                this.setState({ loading: false });
            });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.students;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ districtList: data, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getDistricts - ' + error.message);
            });
    }

    async getSites(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSitesByDistrictId?id=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ siteList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getSites - ' + error.message);
            });
    }

    async getGrades() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetGrades', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ gradeList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getGrades - ' + error.message);
            });
    }

    async getSitesByDistrictId(districtId) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSitesByDistrictId?id=' + districtId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ siteList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSitesByDistrictId - ' + error.message, 'color:#ff0000')
            });
    }

    async exportToCSV() {
        this.setState({ loading: true });
        var url = "";
        let filename;
        let tempfilename;
        var dto;

        if (this.state.reportingType == 'vs') {
            tempfilename = "VisionScreeningResults";
            url = "api/Student/ExportVisionScreeningReport"
            dto = {
                From: this.state.from,
                To: this.state.to,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == '' ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == '' ? null : this.state.siteId
            }
        }
        else if (this.state.reportingType == 'mvc') {
            tempfilename = "MobileVisionClinicResults";
            url = "api/Exam/ExportExamSearchResults";
            dto = {
                From: this.state.from,
                To: this.state.to,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == '' ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == '' ? null : this.state.siteId,
                MVCVisitLocationId: this.state.mvcSiteLocationId == userFunctions.GuidEmpty() || this.state.mvcSiteLocationId == 0 || this.state.mvcSiteLocationId == '' ? null : this.state.mvcSiteLocationId
            }
        }
        else if (this.state.reportingType == 'disp') {
            tempfilename = "DispensedFrames";
            url = "api/Lab/ExportDispensedFramesResults"
            dto = {
                From: this.state.from,
                To: this.state.to,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == '' ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == '' ? null : this.state.siteId,
                MVCVisitLocationId: this.state.mvcSiteLocationId == userFunctions.GuidEmpty() || this.state.mvcSiteLocationId == 0 || this.state.mvcSiteLocationId == '' ? null : this.state.mvcSiteLocationId
            }
        }
        else if (this.state.reportingType === 'repl') {
            tempfilename = "ReplacementExportResults"
            url = "api/student/ExportReplacementSearchResults"
            var dto = {
                DateFormReceivedFrom: this.state.dateFormReceivedFrom ?? null,
                DateFormReceivedTo: this.state.dateFormReceivedTo ?? null,
                DateMailedFrom: this.state.dateMailedFrom ?? null,
                DateMailedTo: this.state.dateMailedTo ?? null,
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 || this.state.districtId == "" ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 || this.state.siteId == "" ? null : this.state.siteId,
            }
        }

        const token = await authService.getAccessToken();
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then((res) => {
                const header = res.headers.get('Content-Disposition');
                const parts = header.split(';');
                if (parts[1]) {
                    filename = parts[1].split('=')[1];
                }
                else {
                    filename = tempfilename;
                }
                return res.blob();
            })
            .then(data => {
                FileHelper.DownloadFile(data, filename, "text/csv");
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in exportToCSV - ' + error.message);
                this.setState({ loading: false });
            });
    }
}